import React from "react";
import Layout from "../../components/Layout";
import { OverlayTrigger, Popover } from "react-bootstrap";
import gear from "../../img/settings-gear.png";
import dropdown from "../../img/settings-dropdown.png";
import signature from "../../img/signature-section.png";

const rowHeight = {
  marginTop: "1rem"
};

const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Email Signature Copied!</Popover.Title>
    <Popover.Content>
      Your email signature has been copied to your clipboard!
    </Popover.Content>
  </Popover>
);

var imagePreviewStyle = {
  height: "164.5px",
  width: "140px",
  display: "none",
  marginTop: "12px"
};

function EmailWithVideo(props) {
  if (props.data) {
    return (
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          {props.data.yesSimplified !== "yesSimplified" && <tr style={{ height: "0pt" }}>
            <td
              style={{
                border: "none",
                verticalAlign: "top",
                padding: "2pt",
                float: "left",
                paddingLeft: "4pt"
              }}
            >
            </td>
          </tr>}
          <tr style={{ height: "0pt" }}>
            {/* Left Side */}
            {/*[if gte mso 9]>
                <table><tr><td>
            <![endif]*/}
            <td
              style={{
                borderWidth: "1pt 4pt 1pt 1pt",
                borderStyle: "solid",
                borderColor: "#ffffff #ffffff #ffffff #ffffff",
                verticalAlign: "middle",
                padding: "5pt 12pt 5pt 5pt",
                maxWidth: "160px",
                minWidth: "130px",
                float: "left"
              }}
            >
              <div style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt", textAlign: "center", maxWidth: "160px", minWidth: "130px" }}>
                <img
                  style={{ fontSize: "12.8px", borderRadius: "50%" }}
                  tabIndex={0}
                  src={props.data.image}
                  alt={props.data.name}
                  width={80}
                  height={94}
                />
              </div>
              <table
                style={{
                  marginTop: "7px"
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        boxShadow: "4px 4px 0px 0px rgba(196,196,196,1)",
                        backgroundColor: "#007eb5",
                        padding: "1px 0px 6px 0px",
                        borderRadius: "2px",
                        color: "white",
                        textAlign: "center",
                        maxWidth: "160px",
                        minWidth: "130px"
                      }}
                    >
                      <a href={props.data.videoLink} target="_blank" rel="noopener noreferrer" style={{paddingLeft: "9px",paddingRight: "6px"}}>
                        <span
                          style={{
                            paddingTop: "5px",
                            fontSize: "12pt",
                            verticalAlign: "bottom",
                            display: "inline-block",
                            color: "white",
                            fontWeight: "bold",
                            whiteSpace: "pre-wrap",
                            fontFamily: "arial, helvetica, sans-serif"
                          }}
                        >
                          About Me
                        </span>
                        &nbsp;
                        <img
                          src="https://www.3playmedia.com/wp-content/uploads/play-button-2.png"
                          alt="Play Button"
                          style={{
                            verticalAlign: "sub",
                            display: "inline-block",
                            width: "20px",
                            height: "20px"
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            {/* Right Side */}
            {/*[if gte mso 9]>
                </td><td>
            <![endif]*/}
            <td
              style={{
                verticalAlign: "top",
                padding: "2pt",
                borderWidth: "1pt 1pt 1pt 4pt",
                borderStyle: "solid",
                borderColor: "#ffffff #ffffff #ffffff #007EB5",
                float: "left",
                paddingLeft: "10pt"
              }}
            >
              {/* Name and Position */}
              <p dir="ltr" style={{ minHeight: "26px", lineHeight: "1.368", marginTop: "6pt", marginBottom: "0pt" }}>
                <span
                  style={{
                    fontSize: "14pt",
                    color: "#007eb5",
                    backgroundColor: "transparent",
                    fontWeight: "bold",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                    fontFamily: "arial, helvetica, sans-serif"
                  }}
                >
                  {props.data.name}
                </span>
                <span
                  style={{
                    fontSize: "14pt",
                    color: "#4f4f4f",
                    backgroundColor: "transparent",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                    fontFamily: "arial, helvetica, sans-serif"
                  }}
                >
                  &nbsp;●&nbsp;
                  {props.data.title}
                </span>
              </p>
              {/* Email */}
              <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
                <a href={"mailto:".concat(props.data.email)} target="_blank" rel="noopener noreferrer">
                  <span
                    style={{
                      fontSize: "11pt",
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif"
                    }}
                  >
                    {props.data.email}
                  </span>
                </a>
                <span
                  style={{
                    fontSize: "11pt",
                    color: "#4f4f4f",
                    backgroundColor: "transparent",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                    fontFamily: "arial, helvetica, sans-serif"
                  }}
                >
                  &nbsp;●&nbsp;
                  <a href="https://www.3playmedia.com" target="_blank" rel="noopener noreferrer">
                    www.3playmedia.com
                  </a>
                </span>
              </p>
              {/* Phone Numbers */}
              <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
                {props.data.phone && (
                  <span
                    style={{
                      fontSize: "11pt",
                      color: "#007eb5",
                      fontWeight: "bold",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif"
                    }}
                  >
                    work:&nbsp;
                    <span
                      style={{
                        fontSize: "11pt",
                        fontWeight: 500,
                        color: "#4f4f4f",
                        backgroundColor: "transparent",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                        fontFamily: "arial, helvetica, sans-serif"
                      }}
                    >
                      {props.data.phone}
                    </span>
                  </span>
                )}
                {props.data.cell && (
                  <span
                    style={{
                      fontSize: "11pt",
                      color: "#007eb5",
                      fontWeight: "bold",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif"
                    }}
                  >
                    &nbsp;&nbsp;cell:&nbsp;
                    <span
                      style={{
                        fontSize: "11pt",
                        fontWeight: 500,
                        color: "#4f4f4f",
                        backgroundColor: "transparent",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                        fontFamily: "arial, helvetica, sans-serif"
                      }}
                    >
                      {props.data.cell}
                    </span>
                  </span>
                )}
              </p>
              {/* Schedule a Meeting */}
              {props.data.meetingLink && (
              <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
                <a
                  href={props.data.meetingLink}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "rgb(0, 126, 181)",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontWeight: "bold",
                      fontFamily: "arial, helvetica, sans-serif",
                      textDecoration: "none"
                    }}
                  >
                    Schedule a Meeting with Me!
                  </span>
                </a>
              </p>
              )}
              {/* Social Media Icons and Logo */}
              <div>
                <p dir="ltr" style={{ lineHeight: "1.368", marginTop: "4pt", marginBottom: "0pt" }}>
                  <a
                    href="https://www.facebook.com/3PlayMedia/"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        color: "#4f4f4f",
                        backgroundColor: "transparent",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                        fontFamily: "arial, helvetica, sans-serif",
                        textDecoration: "none"
                      }}
                    >
                      <img
                        src="https://www.3playmedia.com/wp-content/uploads/facebook-2.png"
                        alt="Facebook"
                        width={30}
                        height={30}
                      />
                    </span>
                  </a>
                  &nbsp;
                  <a
                    href="https://twitter.com/3playmedia"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        color: "#4f4f4f",
                        backgroundColor: "transparent",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                        fontFamily: "arial, helvetica, sans-serif",
                        textDecoration: "none"
                      }}
                    >
                      <img
                        src="https://www.3playmedia.com/wp-content/uploads/twitter.png"
                        alt="Twitter"
                        width={30}
                        height={30}
                      />
                    </span>
                  </a>
                  &nbsp;
                  <a
                    href="https://www.linkedin.com/company/3play-media/"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        color: "#4f4f4f",
                        backgroundColor: "transparent",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                        fontFamily: "arial, helvetica, sans-serif",
                        textDecoration: "none"
                      }}
                    >
                      <img
                        src="https://www.3playmedia.com/wp-content/uploads/linkedin.png"
                        alt="LinkedIn"
                        width={30}
                        height={30}
                      />
                    </span>
                  </a>
                  &nbsp;
                  <a
                    href="https://www.instagram.com/3playmedia/?hl=en"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        color: "#4f4f4f",
                        backgroundColor: "transparent",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                        fontFamily: "arial, helvetica, sans-serif",
                        textDecoration: "none"
                      }}
                    >
                      <img
                        src="https://www.3playmedia.com/wp-content/uploads/instagram-1.png"
                        alt="Instagram"
                        width={30}
                        height={30}
                      />
                    </span>
                  </a>
                  &nbsp;
                  <span style={{ paddingRight: "10px" }}></span>
                  <a
                    href="https://www.3playmedia.com/audio-description-with-3play/"
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        color: "#4f4f4f",
                        backgroundColor: "transparent",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                        fontFamily: "arial, helvetica, sans-serif",
                        textDecoration: "none"
                      }}
                    >
                      <img
                        src="https://www.3playmedia.com/wp-content/uploads/ad-required.png"
                        alt="Audio Description is required by law!"
                        width={132}
                        height={42.5}
                      />
                    </span>
                  </a>
                </p>
              </div>
            </td>
            {/*[if gte mso 9]>    
</td></tr></table>
<![endif]*/}
          </tr>
        </tbody>
      </table>
    );
  }
}

function EmailWithoutVideo(props) {
  return (
    <table style={{ border: "none", borderCollapse: "collapse" }}>
      <tbody>
        {props.data.yesSimplified !== "yesSimplified" && <tr style={{ height: "0pt" }}>
          <td
            style={{
              border: "none",
              verticalAlign: "top",
              padding: "2pt",
              float: "left",
              paddingLeft: "4pt"
            }}
          >
          </td>
        </tr>}
        <tr style={{ height: "0pt" }}>
          {/* Left Side */}
          <td
            style={{
              borderWidth: "1pt 1pt 1pt 1pt",
              borderStyle: "solid",
              borderColor: "#ffffff #ffffff #ffffff #ffffff",
              padding: "5pt 12pt 5pt 5pt",
              maxWidth: "160px",
              float: "left",
              verticalAlign: "middle"
            }}
          >
            <div style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt", textAlign: "center" }}>
              <img
                style={{ fontSize: "12.8px", borderRadius: "50%" }}
                tabIndex={0}
                src={props.data.image}
                alt={props.data.name}
                width={80}
                height={94}
              />
            </div>
            <a href="https://www.3playmedia.com" target="_blank" rel="noopener noreferrer">
              <span
                style={{
                  fontSize: "12pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                <img
                  src="https://www.3playmedia.com/wp-content/uploads/logo-3.52.05-PM.png"
                  alt="3Play Media Logo"
                  style={{
                    marginTop: "5px",
                    width: "130px"
                  }}
                />
              </span>
            </a>
          </td>
          {/* Right Side */}
          <td
            style={{
              verticalAlign: "top",
              padding: "2pt",
              borderWidth: "1pt 1pt 1pt 4pt",
              borderStyle: "solid",
              borderColor: "#ffffff #ffffff #ffffff #007EB5",
              float: "left",
              paddingLeft: "10pt"
            }}
          >
            {/* Name and Position */}
            <p dir="ltr" style={{ minHeight: "26px", lineHeight: "1.368", marginTop: "6pt", marginBottom: "0pt" }}>
              <span
                style={{
                  fontSize: "14pt",
                  color: "#007eb5",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                {props.data.name}
              </span>
              <span
                style={{
                  fontSize: "14pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                &nbsp;●&nbsp;
                {props.data.title}
              </span>
            </p>
            {/* Email and Website */}
            <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
              <a href={"mailto:".concat(props.data.email)} target="_blank" rel="noopener noreferrer">
                <span
                  style={{
                    fontSize: "11pt",
                    color: "#4f4f4f",
                    backgroundColor: "transparent",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                    fontFamily: "arial, helvetica, sans-serif"
                  }}
                >
                  {props.data.email}
                </span>
              </a>
              <span
                style={{
                  fontSize: "11pt",
                  color: "#4f4f4f",
                  backgroundColor: "transparent",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                  fontFamily: "arial, helvetica, sans-serif"
                }}
              >
                &nbsp;●&nbsp;
                <a href="https://www.3playmedia.com" target="_blank" rel="noopener noreferrer">
                  www.3playmedia.com
                </a>
              </span>
            </p>
            {/* Phone Numbers */}
            <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
              {props.data.phone && (
                <span
                  style={{
                    fontSize: "11pt",
                    color: "#007eb5",
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                    fontFamily: "arial, helvetica, sans-serif"
                  }}
                >
                  work:&nbsp;
                  <span
                    style={{
                      fontSize: "11pt",
                      fontWeight: 500,
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif"
                    }}
                  >
                    {props.data.phone}
                  </span>{" "}
                </span>
              )}
              {props.data.cell && (
                <span
                  style={{
                    fontSize: "11pt",
                    color: "#007eb5",
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                    fontFamily: "arial, helvetica, sans-serif"
                  }}
                >
                  &nbsp;&nbsp;cell:&nbsp;
                  <span
                    style={{
                      fontSize: "11pt",
                      fontWeight: 500,
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif"
                    }}
                  >
                    {props.data.cell}
                  </span>{" "}
                </span>
              )}
            </p>
            {/* Schedule a Meeting */}
            {props.data.meetingLink && (
              <p dir="ltr" style={{ minHeight: "21px", lineHeight: "1.368", marginTop: "1pt", marginBottom: "0pt" }}>
                <a
                  href={props.data.meetingLink}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "rgb(0, 126, 181)",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontWeight: "bold",
                      fontFamily: "arial, helvetica, sans-serif",
                      textDecoration: "none"
                    }}
                  >
                    Schedule a Meeting with Me!
                  </span>
                </a>
                
              </p>
            )}
            {/* Social Media Icons and Logo */}
            <div>
              <p dir="ltr" style={{ lineHeight: "1.368", marginTop: "0pt", marginBottom: "0pt" }}>
                <a
                  href="https://www.facebook.com/3PlayMedia/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif",
                      textDecoration: "none"
                    }}
                  >
                    <img
                      src="https://www.3playmedia.com/wp-content/uploads/facebook-2.png"
                      alt="Facebook"
                      width={30}
                      height={30}
                    />
                  </span>
                </a>
                &nbsp;
                <a
                  href="https://twitter.com/3playmedia"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif",
                      textDecoration: "none"
                    }}
                  >
                    <img
                      src="https://www.3playmedia.com/wp-content/uploads/twitter.png"
                      alt="Twitter"
                      width={30}
                      height={30}
                    />
                  </span>
                </a>
                &nbsp;
                <a
                  href="https://www.linkedin.com/company/3play-media/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif",
                      textDecoration: "none"
                    }}
                  >
                    <img
                      src="https://www.3playmedia.com/wp-content/uploads/linkedin.png"
                      alt="LinkedIn"
                      width={30}
                      height={30}
                    />
                  </span>
                </a>
                &nbsp;
                <a
                  href="https://www.instagram.com/3playmedia/?hl=en"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif",
                      textDecoration: "none"
                    }}
                  >
                    <img
                      src="https://www.3playmedia.com/wp-content/uploads/instagram-1.png"
                      alt="Instagram"
                      width={30}
                      height={30}
                    />
                  </span>
                </a>
                &nbsp;
                <a
                  href="https://www.3playmedia.com/audio-description-with-3play/"
                  style={{ textDecoration: "none" }}
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      paddingLeft: "10px",
                      color: "#4f4f4f",
                      backgroundColor: "transparent",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                      fontFamily: "arial, helvetica, sans-serif",
                      textDecoration: "none"
                    }}
                  >
                    <img
                      src="https://www.3playmedia.com/wp-content/uploads/ad-required.png"
                      alt="Audio Description is required by law!"
                      width={132}
                      height={42.5}
                    />
                  </span>
                </a>
              </p>
            </div>
          </td>
        </tr>  
      </tbody>
    </table>
  );
}

export default class EmailSignatureGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
      simplifiedSignatureOption: "noSimplified",
      selectedVideoOption: "noVideo",
      selectedMeetingOption: "noMeeting",
      formSubmitted: false,
      hasVideo: false,
      formData: {}
    };
    this.myRef = React.createRef();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.value });
    var imagePreview = document.getElementById("image-preview");
    imagePreview.src = e.target.value;
    imagePreviewStyle = {
      height: "164.5px",
      width: "140px",
      display: "block",
      marginTop: "12px"
    };
  };

  copyToClipboard = e => {
    var str = document.getElementById("email-sig").innerHTML;
    function listener(e) {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  };

  handleSimplifiedSignatureChange = changeEvent => {
    this.setState({
      simplifiedSignatureOption: changeEvent.target.value
    });
  };

  handleVideoOptionChange = changeEvent => {
    this.setState({
      selectedVideoOption: changeEvent.target.value
    });
  };

  handleMeetingOptionChange = changeEvent => {
    this.setState({
      selectedMeetingOption: changeEvent.target.value
    });
  };

  handleSubmit = e => {
    this.setState({
      formSubmitted: false,
      hasVideo: false
    });
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1];
    }
    this.setState({
      formData: data
    });
    // fetch("/", {
    //   method: "POST",
    //   body: encode({
    //     "form-name": form.getAttribute("name"),
    //     ...this.state
    //   })
    // })
    //   .then(() => this.showEmailSignature())
    //   .catch(error => alert(error));
    this.validateAndShowForm(data);
  };

  validateAndShowForm(formData) {
    // Check to make sure all required fields are filled out
    if (formData.yesVideo && !formData.videoLink) {
      return;
    }
    if (formData.yesMeeting && !formData.meetingLink) {
      return;
    }
    if (!formData.name || !formData.title || !formData.email || !formData.image) {
      return;
    }
    console.log('printing formData: ', formData);
    if (formData.yesSimplified) {
      this.setState({
        formSubmitted: true,
        hasVideo: false
      })
    } else if (formData.yesVideo && formData.videoLink) {
      window.scrollTo(0, this.myRef.current.offsetTop);
      this.setState({
        formSubmitted: true,
        hasVideo: true
      });
    } else if (formData.noVideo && !formData.videoLink) {
      window.scrollTo(0, this.myRef.current.offsetTop);
      this.setState({
        formSubmitted: true,
        hasVideo: false
      });
    }
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>3Play Media's Email Signature Generator</h1>
              <p>Generate an email signature to copy to your Gmail.</p>
              <form
                name="email-signature-generator"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <div className="row" style={rowHeight}>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="label">Do you want a simplified version of the signature?*</label>
                      <p style={{margin: "0rem", fontSize: "12px", marginTop: "-4px"}}>A simplified version is best for 3Players that send a lot of external emails and communicate often with customers and non-3Players.</p>
                      <div className="control">
                        <div style={{ display: `inline-block`, paddingTop: "3px" }}>
                          <input
                            className="radio"
                            type={"radio"}
                            id={"noSimplified"}
                            name={"noSimplified"}
                            value={"noSimplified"}
                            checked={this.state.simplifiedSignatureOption === "noSimplified"}
                            onChange={this.handleSimplifiedSignatureChange}
                          />
                          <label
                            htmlFor={"noSimplified"}
                            style={{
                              paddingLeft: `4px`
                            }}
                          >
                            No
                          </label>
                        </div>
                        <div
                          style={{
                            paddingLeft: `10px`,
                            display: `inline-block`
                          }}
                        >
                          <input
                            className="radio"
                            type={"radio"}
                            id={"yesSimplified"}
                            name={"yesSimplified"}
                            value={"yesSimplified"}
                            checked={this.state.simplifiedSignatureOption === "yesSimplified"}
                            onChange={this.handleSimplifiedSignatureChange}
                          />
                          <label
                            htmlFor={"yesSimplified"}
                            style={{
                              paddingLeft: `4px`
                            }}
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                {this.state.simplifiedSignatureOption === "noSimplified" && <div className="row" style={rowHeight}>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="label">Do you have a Wistia video?*</label>
                      <div className="control">
                        <div style={{ display: `inline-block`, paddingTop: "3px" }}>
                          <input
                            className="radio"
                            type={"radio"}
                            id={"noVideo"}
                            name={"noVideo"}
                            value={"noVideo"}
                            checked={this.state.selectedVideoOption === "noVideo"}
                            onChange={this.handleVideoOptionChange}
                          />
                          <label
                            htmlFor={"noVideo"}
                            style={{
                              paddingLeft: `4px`
                            }}
                          >
                            No
                          </label>
                        </div>
                        <div
                          style={{
                            paddingLeft: `10px`,
                            display: `inline-block`
                          }}
                        >
                          <input
                            className="radio"
                            type={"radio"}
                            id={"yesVideo"}
                            name={"yesVideo"}
                            value={"yesVideo"}
                            checked={this.state.selectedVideoOption === "yesVideo"}
                            onChange={this.handleVideoOptionChange}
                          />
                          <label
                            htmlFor={"yesVideo"}
                            style={{
                              paddingLeft: `4px`
                            }}
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-6"
                    style={this.state.selectedVideoOption === "noVideo" ? { display: "none" } : { display: "block" }}
                  >
                    <div className="field">
                      <label className="label" htmlFor={"videoLink"}>
                        Video Link
                      </label>

                      <div className="control">
                        <input
                          className="input"
                          type={"text"}
                          name={"videoLink"}
                          onChange={this.handleChange}
                          id={"videoLink"}
                        />
                      </div>
                    </div>
                  </div>
                </div>}

                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <div className="row" style={rowHeight}>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="label">Do you have a meeting link?*</label>
                      <div className="control">
                        <div style={{ display: `inline-block`, paddingTop: "3px" }}>
                          <input
                            className="radio"
                            type={"radio"}
                            id={"noMeeting"}
                            name={"noMeeting"}
                            value={"noMeeting"}
                            checked={this.state.selectedMeetingOption === "noMeeting"}
                            onChange={this.handleMeetingOptionChange}
                          />
                          <label
                            htmlFor={"noMeeting"}
                            style={{
                              paddingLeft: `4px`
                            }}
                          >
                            No
                          </label>
                        </div>
                        <div
                          style={{
                            paddingLeft: `10px`,
                            display: `inline-block`
                          }}
                        >
                          <input
                            className="radio"
                            type={"radio"}
                            id={"yesMeeting"}
                            name={"yesMeeting"}
                            value={"yesMeeting"}
                            checked={this.state.selectedMeetingOption === "yesMeeting"}
                            onChange={this.handleMeetingOptionChange}
                          />
                          <label
                            htmlFor={"yesMeeting"}
                            style={{
                              paddingLeft: `4px`
                            }}
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-6"
                    style={
                      this.state.selectedMeetingOption === "noMeeting" ? { display: "none" } : { display: "block" }
                    }
                  >
                    <div className="field">
                      <label className="label" htmlFor={"meetingLink"}>
                        Meeting Link
                      </label>

                      <div className="control">
                        <input
                          className="input"
                          type={"text"}
                          name={"meetingLink"}
                          onChange={this.handleChange}
                          id={"meetingLink"}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Captcha */}
                <input type="hidden" name="form-name" value="email-signature-generator" />
                <div hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>

                <div className="row" style={rowHeight}>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="label" htmlFor={"name"}>
                        Full Name*
                      </label>
                      <label className="sublabel" style={{ fontSize: "12px", marginTop: "-4px" }}>
                        Feel free to add your pronoun in parentheses after your name!
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={"text"}
                          name={"name"}
                          onChange={this.handleChange}
                          id={"name"}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                    <label className="sublabel" style={{ fontSize: "12px", marginTop: "-4px" }}>
                        &nbsp;
                      </label>
                      <label className="label" htmlFor={"title"}>
                        Title/Department*
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={"text"}
                          name={"title"}
                          onChange={this.handleChange}
                          id={"title"}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={rowHeight}>
                  <div className="col-sm-12">
                    <div className="field">
                      <label className="label" htmlFor={"email"}>
                        3Play Email Address*
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={"text"}
                          name={"email"}
                          onChange={this.handleChange}
                          id={"email"}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={rowHeight}>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="label" htmlFor={"phone"}>
                        Phone Number
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={"tel"}
                          name={"phone"}
                          onChange={this.handleChange}
                          id={"phone"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="label" htmlFor={"cell"}>
                        Cell Number
                      </label>
                      <div className="control">
                        <input className="input" type={"tel"} name={"cell"} onChange={this.handleChange} id={"cell"} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field" style={{marginTop: "1.5rem"}}>
                  <label className="label" htmlFor={"file"}>
                    Profile Photo Link*
                  </label>
                  <label className="sublabel" style={{ fontSize: "12px", marginTop: "-4px" }}>
                    From{" "}
                    <a
                      href="https://docs.google.com/spreadsheets/d/1dpq2IX_KMabO4RIX-lKJM6m7CItMBVZjilZzhlOgd00/edit?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this spreadsheet attached
                    </a>
                    , enter the link to the photo you want to use.
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"url"}
                      name={"image"}
                      onChange={this.handleAttachment}
                      id={"image"}
                      required
                    />
                  </div>
                  <img id="image-preview" alt="Preview for uploaded file" style={imagePreviewStyle} />
                </div>

                <div className="field">
                  <button className="button is-link" style={{ float: "right" }}>
                    Create Email Signature
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className="section" ref={this.myRef}>
          {this.state.formSubmitted && (
            <div className="container">
              <div className="content">
                <h1>Here's your email signature!</h1>
                <div id="email-sig" style={{ border: "1px solid #DEEFF5" }}>
                  {this.state.hasVideo && <EmailWithVideo data={this.state.formData} />}
                  {!this.state.hasVideo && <EmailWithoutVideo data={this.state.formData} />}
                </div>
                <br />
                <h4>Steps to Copy Your Signature into Gmail</h4>
                <ol>
                  <li>Click the following button to copy your email signature to your clipboard.</li>
                  <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                    <button
                      style={{ marginTop: "4px" }}
                      className="button is-link"
                      id="email-signature"
                      onClick={this.copyToClipboard}
                    >
                      Copy to Clipboard
                    </button>
                  </OverlayTrigger>
                  <li
                    style={{
                      marginTop: "10px"
                    }}
                  >
                    Open Gmail
                  </li>
                  <a
                    href="https://mail.google.com/mail/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginTop: "4px", color: "white !important" }}
                    className="button is-link"
                    id="email-signature"
                  >
                    <i className="fa fa-envelope" aria-hidden="true"></i>&nbsp;Open Gmail
                  </a>

                  <li
                    style={{
                      marginTop: "10px"
                    }}
                  >
                    Click on the settings gear icon in the upper right hand corner of your page
                  </li>
                  <img src={gear} style={{ width: "250px" }} alt="Gmail settings gear" />
                  <li
                    style={{
                      marginTop: "10px"
                    }}
                  >
                    In the dropdown, click on settings
                  </li>
                  <img src={dropdown} style={{ width: "250px" }} alt="Gmail gear dropdown" />
                  <li
                    style={{
                      marginTop: "10px"
                    }}
                  >
                    Scroll down to the section that says "Signature" and copy your signature into the text box
                  </li>
                  <img src={signature} style={{ width: "800px" }} alt="Gmail settings signature section" />
                  <li
                    style={{
                      marginTop: "10px"
                    }}
                  >
                    Remember to save your changes at the bottom of the page!
                  </li>
                </ol>
              </div>
            </div>
          )}
        </section>
      </Layout>
    );
  }
}